import axios from "axios";
import qs from "qs";

export const obtenerDataFromDecryptAPI = (pm) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `/pagomovil?action=validar-pm`,
            qs.stringify({pm: pm})
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        });
    })
}

/*TODO crear conexión a servlet que valide el v3 o el v2 para obtener la url de TGR y redireccionar al pago*/
export const pagarCotizacionPorMovilAPI = (solicitud) => {
    return new Promise((resolve, reject) => {
        axios.post(
            `/pagomovil?action=pagar-cotizacion`,
            qs.stringify({
                pm: solicitud.pm,
                t: solicitud.token,
                r: solicitud.r
            })
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        })
    })
}
