<template>
  <div>
    <v-container>
      <v-card class="mx-auto" v-if="infoSolicitud != null">
        <v-card-title>
          Pago Móvil
        </v-card-title>
        <div v-if="infoSolicitud.id_solicitud_cliente > 0">
          <v-card-text v-if="mensajeFinal === 'PAGADA'">
            <v-alert
                class="ma-5"
                type="info"
                outlined
                border="left"
            >
              La solicitud {{ infoSolicitud.id_solicitud_cliente }} ya fue pagada
            </v-alert>
          </v-card-text>
          <v-card-text v-else-if="mensajeFinal === 'REDIRECCIONANDO'">
            <v-alert
                class="ma-5 text-center"
                color="primary"
                outlined
            >
              Redireccionando al botón de pago TGR
              <v-progress-circular
                  class="ml-3"
                  size="16"
                  width="2"
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-alert>
          </v-card-text>
          <v-card-text v-else class="d-flex flex-column align-center">
            <div class="py-4 d-flex flex-column align-center">
              <p class="text-h2 mb-0 pb-4 black--text">{{ infoSolicitud.precio_total | clp }}</p>
              <div class="mb-5 mt-3 d-flex flex-column align-center">
                <p class="text-h5 mb-0 pb-0">Solicitud {{ infoSolicitud.id_solicitud_cliente }}</p>
                <p class="text-h6 mb-3 pb-0">Rut {{ infoSolicitud.rut_cliente | formatearRut }}</p>
                <p class="text-h7 mb-0">
                  <v-icon size="16">mdi-calendar</v-icon>
                  {{ infoSolicitud.fecha_creacion | toDate }}
                </p>
              </div>
            </div>
            <div>
              <v-alert type="info" outlined border="left">
                Una vez finalizado el pago DEBE pasar por caja para obtener su número de carátula
              </v-alert>
            </div>
            <div class="d-flex flex-column align-center">
              <vue-hcaptcha
                  color="primary"
                  x-large
                  sitekey="6e459061-5813-4799-8bc9-c5aae72a22c4"
                  @verify="onVerify"
                  @error="onError"
                  v-if="hCaptcha"
              ></vue-hcaptcha>
              <v-alert
                  class="mt-3"
                  v-if="mensajeError !== ''"
                  type="warning"
                  border="left"
                  outlined
              >
                {{ mensajeError }}
              </v-alert>
              <v-btn
                  color="primary"
                  x-large
                  @click.stop="pagar"
                  :loading="pagandoSolicitud"
              >
                <v-icon
                    size="16"
                    left
                >
                  mdi-credit-card-outline
                </v-icon>
                Pagar
              </v-btn>
            </div>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text v-if="mensajeFinal === 'PAGADA'">
            <v-alert
                class="ma-5"
                type="info"
                outlined
                border="left"
            >
              El saldo de la carátula {{ infoSolicitud.numero_caratula_FK }} ya fue pagado
            </v-alert>
          </v-card-text>
          <v-card-text v-else-if="mensajeFinal === 'REDIRECCIONANDO'">
            <v-alert
                class="ma-5 text-center"
                color="primary"
                outlined
            >
              Redireccionando al botón de pago TGR
              <v-progress-circular
                  class="ml-3"
                  size="16"
                  width="2"
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </v-alert>
          </v-card-text>
          <v-card-text v-else class="d-flex flex-column align-center">
            <div class="py-4 d-flex flex-column align-center">
              <p class="text-h2 mb-0 pb-4 black--text">{{ infoSolicitud.precio_total | clp }}</p>
              <div class="mb-5 mt-3 d-flex flex-column align-center">
                <p class="text-h5 mb-0 pb-0">Saldo de carátula N° {{ infoSolicitud.numero_caratula_FK }}</p>
                <p class="text-h6 mb-3 pb-0">Rut {{ infoSolicitud.rut_cliente | formatearRut }}</p>
                <p class="text-h7 mb-0">
                  <v-icon size="16">mdi-calendar</v-icon>
                  {{ infoSolicitud.fecha_creacion | toDate }}
                </p>
              </div>
            </div>
            <!--
            <div>
              <v-alert type="info" outlined border="left">
                Una vez finalizado el pago DEBE pasar por caja para obtener su número de carátula
              </v-alert>
            </div>
            -->
            <div class="d-flex flex-column align-center">
              <vue-hcaptcha
                  color="primary"
                  x-large
                  sitekey="6e459061-5813-4799-8bc9-c5aae72a22c4"
                  @verify="onVerify"
                  @error="onError"
                  v-if="hCaptcha"
              ></vue-hcaptcha>
              <v-alert
                  class="mt-3"
                  v-if="mensajeError !== ''"
                  type="warning"
                  border="left"
                  outlined
              >
                {{ mensajeError }}
              </v-alert>
              <v-btn
                  color="primary"
                  x-large
                  @click.stop="pagar"
                  :loading="pagandoSolicitud"
              >
                <v-icon
                    size="16"
                    left
                >
                  mdi-credit-card-outline
                </v-icon>
                Pagar
              </v-btn>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import pagoMovilEntity from "@/model/pago-movil/pago-movil.entity";
import {rutFormat} from "rut-helpers";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  components: {VueHcaptcha},
  name: "PagoMovilView",
  data() {
    return {
      mensajeError: "",
      mensajeFinal: "",
      pagandoSolicitud: false,
      //
      urlTrx: null,
      infoSolicitud: null,
      hCaptcha: false,
      hToken: ''
    }
  },
  filters: {
    formatearRut(rut) {
      return rutFormat(rut);
    }
  },
  mounted() {
    //ejemplo:
    //XeKLfxkCMB4Ms7FtggF6AumhgOPYHtBm3cCELvlhwBIjKU3AzBxrv%2FhKdfw335Qa0DI0hYgPmAYwsOzDmiD%2B9Cafmz6nPu8%2BMlbubYanH2hiHvZ7G4T1CI53J%2FUhTkGwEBD6sFmKoxGesY4iEhtlangcHuJPx2ln
    //this.urlTrx = this.$route.params.codigotrx;
    this.urlTrx = this.$route.query.pm;
    this.decodificar();
  },
  methods: {
    decodificar() {
      pagoMovilEntity.decodificarUrl(this.urlTrx)
          .then((response) => {
            this.infoSolicitud = response;
          })
          .catch(error => alert(error));
    },
    async pagar() {
      this.pagandoSolicitud = true;

      //Si es con recaptcha
      if (!this.hCaptcha && !this.hToken) {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        const token = await this.$recaptcha('login')

        // Do stuff with the received token.
        this.infoSolicitud["token"] = token;
        this.infoSolicitud["pm"] = this.urlTrx;
        this.infoSolicitud["r"] = true;
      }

      pagoMovilEntity.pagarCotizacion(this.infoSolicitud)
          .then((response) => {
            console.log(response);
            if (response.code === "ERROR:RECAPTCHAV3") {
              this.hCaptcha = true;
              //Mensaje de que rellene el captcha
              this.mensajeError = "Favor verifique la opción \"Soy humano\" y vuelva a presionar el botón pagar.";
            } else {
              //redirigir a url de pago tgr
              if (response.obj == "PAGADA") {
                this.mensajeFinal = "PAGADA";
              } else {
                this.mensajeFinal = "REDIRECCIONANDO";
                setTimeout(() => {
                  window.location.href = response.obj;
                }, 2000);
              }
              console.log("respuesta sin recaptcha: ", response);
            }
          })
          .catch((error) => {
            //mostrar error
            console.error(error);
            alert(error.obj);
          })
          .finally(() => {
            this.pagandoSolicitud = false;
          });
    },
    onVerify(token, eKey) {
      console.log('Verified: ', {token, eKey})
      this.infoSolicitud["token"] = token;
      this.infoSolicitud["pm"] = this.urlTrx;
      this.infoSolicitud["r"] = false;
      console.log(this.infoSolicitud);
    },
    onError: (err) => {
      console.log('Error', err)
    },
  },
}
</script>

<style scoped>

</style>
