import {pagarCotizacionPorMovilAPI} from "@/model/pago-movil/pago-movil.repository";

const pagarCotizacion = (solicitud) => {
    return new Promise((resolve, reject) => {
        pagarCotizacionPorMovilAPI(solicitud)
            .then((response) => {
                if (response.data.status) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export default pagarCotizacion;
